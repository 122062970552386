import React, { useState } from 'react';
import './careers.css';
import axios from 'axios';
import { Feature } from '../../components';
import ReCAPTCHA from 'react-google-recaptcha';

const featuresData = [
  {
    title: 'Remote First',
    text: "We are Remote First company so you don’t need to travel to office if you don’t want to.",
  },
  {
    title: 'Work where you Live',
    text: 'You can work where you live. Don’t need to move. Or move to a place where you want to work from. Your choice.',
  },
  {
    title: 'Feed your curiosity',
    text: 'Grow as an expert and leader in the area of interest to you.',
  },
  {
    title: 'Choose your career',
    text: 'You will build career that makes you excited to come to work everyday.',
  },
  {
    title: 'Cultivate well being',
    text: 'We support you to develop holistic plan to live a good life',
  },
]


const Careers = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(!isChecked);
    setShowRecaptcha(!showRecaptcha);

  };

  const handleCaptchaChange = (e) => {
    setIsVerified(!isVerified);
  }

const resumeSubmithandler = (event) => {
  event.preventDefault();

  if(selectedFile)
  {
    const fileType = selectedFile.type;
    const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if(isChecked) {
      if(isVerified) {
        if (validFileTypes.includes(fileType)) {
          const formData = new FormData();
          formData.append('resume', selectedFile);
        
          axios.post('https://internaldevapi.objectnova.ca/sendResume', formData,)
          .then(response => {
              console.log(response);
              alert('Your resume has been submitted successfully');
              setSelectedFile(null)
          }).catch(error => {
              console.error(error);
              setSelectedFile(null)
          });
        } else {
          alert('Invalid file type. Please select a .pdf, .doc, or .docx file');
          setSelectedFile(null)
        }
      } else {
        alert('Please verify that you are not a robot');
        // setSelectedFile(null)
      }
    } else {
      alert('You must agree to the terms and conditions');
    }
  } else {
    alert('Please select a file');
  }
  
}



  return (
    <div className='nova__careers section__padding' id='careers'>
      <div className='nova__careers-heading'>
        <h1 className='gradient__text'>Careers</h1>
      </div>
      <div className='nova__careers-content'>
        <div className='nova__careers-content-container'>
          <h2>Putting people first since day one</h2>
          <p>At ObjectNova Inc., we offer variety, fulfillment, and balance. Work with the world’s top companies, keep up with the latest technology, and flex your skills across industries—all while enjoying the comfort of your own home location.</p>
          <p>We attract the best in the business. People from enterprises, consultancies, agencies, and startups—all drawn to building a career that's 
            both exciting and sustainable.</p>
        </div>
        <div className='nova__careers-content-features'>
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index}/>
          ))}
        </div>
      </div>
      <form className='nova__careers-content__input' onSubmit={resumeSubmithandler}>
          <label className='custom-file-input-label' htmlFor='fileInput'>
          {selectedFile ? selectedFile.name : 'Upload your resume'} 
          </label>
          <input 
            type="file" 
            id='fileInput'
            className='custom-file-input'
            name="resume"
            onChange={handleFileChange} 
          />
          <label className='checkbox'>
              <input 
                type='checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              By submitting, you consent to ObjectNova Inc. processing your information in accordance with our Privacy Policy. We take your privacy seriously; opt out of email updates at any time.
          </label>

          {showRecaptcha && (
            <ReCAPTCHA 
              sitekey = "6Lep4DIpAAAAAKz7vZm22K47XCJO2MoGMcP8lGxB"
              onChange={handleCaptchaChange}
            />
          )}

          <button type="submit">Submit</button>
        </form>
    </div>
  )
}

export default Careers